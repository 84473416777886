.blue-btn {
    color: white;
    border-radius: 50px;  
    position: relative;
    display: inline-block;
    padding: 12px 22px;
    margin: 40px;
    text-decoration: none;
    transition: 0.3s;
    background-color: rgb(0, 113, 227);
    border: none !important;
    font-size: 17px !important;
    line-height: 1.1764805882;
}
.blue-btn:hover {
    background-color: rgb(0, 119, 237);
}