.swiper {
    width: 100%;
    height: 100%;
  }
.custom-swiper-slide {
    text-align: center;
    font-size: 18px;
    background: transparent;
    min-height: 500px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    border-radius: 10px !important;
}
.custom-swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.swiper-pagination {
    position: relative !important;
    bottom: -5px !important;
}
.swiper-button-next, 
.swiper-button-prev {
    color: white !important;
    z-index: 9999;  
}
.swiper-pagination-bullet-active {
    background-color: rgb(0, 119, 237) !important;
}
.fondoimg {
    position: absolute;
    /*object-fit: cover;*/
    border-radius: 10px;
    z-index: 9997;
}
.filtro {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9998;
    border-radius: 10px;
}
.swiper-slide {
    background-color: #f0f0f2;
    position: relative;
}
.swiper-slide h3 {
    color: white;
    text-shadow: 0px 0px 50px black;
    font-size: 55px !important;
}
.contenidocard {
    position: absolute;
    z-index: 9999;
}