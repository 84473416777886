    
    .quarters {
        background-color: #f5f5f7;
    }

    .perfil-image {
        border-radius: 50%;
        object-fit: cover;
    }

    @media only screen and (min-width: 767.99px) {
        .primera {
            min-height: 250px;
        }
        .segunda {
            min-height: 250px;
        }
    }

    @media (min-width: 992px) and (min-width: 1199.99px) {
        .primera {
            min-height: 250px;
        }
        .segunda {
            min-height: 180px;
        }
    }


    

    /*MODO OSCURO*/
    .modo-oscuro .quarters {
        background-color: #1D1D1F !important;
    }