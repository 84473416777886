.card {
    background-color: transparent !important;
    border: none !important;
    transition: all 0.5s ease-out;  
}
.titulo {
    position: absolute;
}
.card .hover {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    padding: 0;
    position: absolute;
    opacity: 0;
    transition: all 0.5s ease-out;
    width: 100%;
    color: white !important;
}

.card:hover .hover {
    opacity: 1;
}

.hover span {
    background: #272729 !important;
    color: #fff;
    padding: 10px;
    border-radius: 5px !important;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
}
.card img {
    width: 60px;
    height: 60px;
}

.card:hover img {
    filter: blur(4px);
}

