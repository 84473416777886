.contenedor h2 {
    font-size: 70px;
    color: #1d1d1f;
    margin: 0px !important;
}

@media only screen and (max-width: 575px) {
    .contenedor h2 {
        font-size: 40px;
    }
}

/*MODO OSCURO*/
.modo-oscuro .contenedor h2 {
    color: white;
}